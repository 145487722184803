import { median } from 'mathjs';
import index_names_dynamics from '../dictionaries/index_names_dymanics.json';
import {
  icons_dict,
  tablet_cards_icons_dict,
} from '../dictionaries/icons_dict.jsx';
import {public_index_dictionary} from "../dictionaries/public_index_dictionary.js";

const colors = {
  1: 'red',
  2: 'blue',
  3: 'green',
  4: 'yellow',
  5: 'magenta',
};

export const formatPublicCardsWidgetData = (data) => {
  return [
    {
      index_name: 'main',
      title: public_index_dictionary,
      index: 0,
      difference: 0,
      id: 0,
      children: Object.keys(data).map((child, metric_index) => {
        return {
          index_name: child,
          title: public_index_dictionary[child],
          index: data[child],
          difference: 0,
          id: `${0}-${metric_index + 1}`,
          dynamics: 0,
          dynamics_value: 0,
          icon: null,
        };
      }),
    },
  ];
};

export const formatCardsWidgetData = (data, language, isTablet) => {
  return Object.keys(data.sub_index)
    .filter((item) => data.sub_index[item])
    .map((index) => {
      return {
        index_name: `index_${index}`,
        title: index_names_dynamics[index][`name_${language}`],
        index: data.sub_index[index] ? data.sub_index[index].toFixed(2) : 0.0,
        difference: 0,
        bgColor: colors[index],
        id: index,
        children: Object.keys(data.metrics)
          .filter(
            (metric) =>
              metric[0] === index.toString() && index_names_dynamics[metric]
          )
          .map((metric, metric_index) => {
            return {
              index_name: `index_${metric}`,
              title: index_names_dynamics[metric][`name_${language}`],
              index: data.metrics[metric]
                ? data.metrics[metric].toFixed(2)
                : 0.0,
              difference: 0,
              bgColor: colors[index],
              id: `${index}-${metric_index + 1}`,
              dynamics: index_names_dynamics[metric].dymanics,
              dynamics_value: index_names_dynamics[metric].dymanics_value,
              icon: !isTablet
                ? icons_dict[metric[0]]
                : tablet_cards_icons_dict[metric[0]],
            };
          }),
      };
    });
};

export const formCardsFromFeature = (data, language) => {
  const sub_index = {};
  const metrics = {};
  Object.keys(data).forEach((prop) => {
    if (prop.includes('index')) {
      const index = prop.split('index_')[1];
      if (index.length === 1) {
        sub_index[index] = data[prop];
      } else {
        metrics[index] = data[prop];
      }
    }
  });

  return formatCardsWidgetData({ sub_index, metrics }, language);
};

export const formatChartWidgetData = (data, language) => {
  return Object.keys(data.sub_index).map((sub_index) => {
    return {
      title: index_names_dynamics[sub_index][`name_${language}`].replaceAll(
        /( )[а-я][^ ]/g,
        (match) => `\n${match.trim()}`
      ),
      index_name: `index_${sub_index}`,
      value: data.sub_index[sub_index]
        ? data.sub_index[sub_index].toFixed(2)
        : 0.0,
    };
  });
};

export const formChartFromFeature = (data, language) => {
  const sub_index = {};
  Object.keys(data)
    .filter((item) => /index_\d{1}/g.test(item) && !/index_\d{3}/g.test(item))
    .forEach((item) => {
      const index = item.split('index_')[1];
      sub_index[index] = data[item];
    });

  return formatChartWidgetData({ sub_index }, language);
};

export const formChartFromZoom10 = (data, language) => {
  const result = {
    index_1: 0,
    index_2: 0,
    index_3: 0,
    index_4: 0,
    index_5: 0,
  };

  const medians = {};
  data.forEach((item) => {
    Object.keys(item.properties).forEach((prop) => {
      if (/index_\d{3}/g.test(prop)) {
        if (medians[prop]) {
          medians[prop].push(item.properties[prop]);
        } else {
          medians[prop] = [item.properties[prop]];
        }
      }
    });
  });
  Object.keys(medians).forEach((indexes) => {
    medians[indexes] = median(medians[indexes]);
  });
  [1, 2, 3, 4, 5].forEach((item) => {
    const regExpSeparator = new RegExp(`index_${item}`, 'g');
    const filteredMedians = Object.keys(medians).filter((index) =>
      index.match(regExpSeparator)
    );
    result[`index_${item}`] =
      filteredMedians.reduce((a, b) => a + medians[b], 0) /
      filteredMedians.length;
  });

  // FIXME Less iterations var
  // const testMedians = {
  //   index_101: [],
  //   index_102: [],
  //   index_103: [],
  //   index_104: [],
  //   index_105: [],
  //   index_106: [],
  //   index_107: [],
  //   index_108: [],
  //   index_109: [],
  //   index_110: [],
  //   index_111: [],
  //   index_112: [],
  //   index_113: [],
  //   index_114: [],
  //   index_115: [],
  //   index_116: [],
  //   index_201: [],
  //   index_202: [],
  //   index_203: [],
  //   index_204: [],
  //   index_205: [],
  //   index_206: [],
  //   index_207: [],
  //   index_208: [],
  //   index_209: [],
  //   index_210: [],
  //   index_211: [],
  //   index_212: [],
  //   index_213: [],
  //   index_214: [],
  //   index_215: [],
  //   index_216: [],
  //   index_217: [],
  //   index_218: [],
  //   index_219: [],
  //   index_220: [],
  //   index_301: [],
  //   index_302: [],
  //   index_303: [],
  //   index_304: [],
  //   index_305: [],
  //   index_306: [],
  //   index_307: [],
  //   index_308: [],
  //   index_309: [],
  //   index_310: [],
  //   index_311: [],
  //   index_312: [],
  //   index_401: [],
  //   index_402: [],
  //   index_403: [],
  //   index_404: [],
  //   index_405: [],
  //   index_406: [],
  //   index_407: [],
  //   index_408: [],
  //   index_409: [],
  //   index_410: [],
  //   index_411: [],
  //   index_412: [],
  //   index_501: [],
  //   index_502: [],
  //   index_503: [],
  //   index_504: [],
  //   index_505: [],
  //   index_506: [],
  //   index_507: [],
  //   index_508: [],
  //   index_509: [],
  //   index_510: [],
  //   index_511: [],
  //   index_512: [],
  //   index_513: [],
  //   index_514: [],
  //   index_515: [],
  //   index_516: [],
  // };
  // const testResult = {
  //   index_1: 0,
  //   index_2: 0,
  //   index_3: 0,
  //   index_4: 0,
  //   index_5: 0,
  // };
  //
  // data.forEach((item) => {
  //   testMedians.index_101.push(item.properties.index_101);
  //   testMedians.index_102.push(item.properties.index_102);
  //   testMedians.index_103.push(item.properties.index_103);
  //   testMedians.index_104.push(item.properties.index_104);
  //   testMedians.index_105.push(item.properties.index_105);
  //   testMedians.index_106.push(item.properties.index_106);
  //   testMedians.index_107.push(item.properties.index_107);
  //   testMedians.index_108.push(item.properties.index_108);
  //   testMedians.index_109.push(item.properties.index_109);
  //   testMedians.index_110.push(item.properties.index_110);
  //   testMedians.index_111.push(item.properties.index_111);
  //   testMedians.index_112.push(item.properties.index_112);
  //   testMedians.index_113.push(item.properties.index_113);
  //   testMedians.index_114.push(item.properties.index_114);
  //   testMedians.index_115.push(item.properties.index_115);
  //   testMedians.index_116.push(item.properties.index_116);
  //   testMedians.index_201.push(item.properties.index_201);
  //   testMedians.index_202.push(item.properties.index_202);
  //   testMedians.index_203.push(item.properties.index_203);
  //   testMedians.index_204.push(item.properties.index_204);
  //   testMedians.index_205.push(item.properties.index_205);
  //   testMedians.index_206.push(item.properties.index_206);
  //   testMedians.index_207.push(item.properties.index_207);
  //   testMedians.index_208.push(item.properties.index_208);
  //   testMedians.index_209.push(item.properties.index_209);
  //   testMedians.index_210.push(item.properties.index_210);
  //   testMedians.index_211.push(item.properties.index_211);
  //   testMedians.index_212.push(item.properties.index_212);
  //   testMedians.index_213.push(item.properties.index_213);
  //   testMedians.index_214.push(item.properties.index_214);
  //   testMedians.index_215.push(item.properties.index_215);
  //   testMedians.index_216.push(item.properties.index_216);
  //   testMedians.index_217.push(item.properties.index_217);
  //   testMedians.index_218.push(item.properties.index_218);
  //   testMedians.index_219.push(item.properties.index_219);
  //   testMedians.index_220.push(item.properties.index_220);
  //   testMedians.index_301.push(item.properties.index_301);
  //   testMedians.index_302.push(item.properties.index_302);
  //   testMedians.index_303.push(item.properties.index_303);
  //   testMedians.index_304.push(item.properties.index_304);
  //   testMedians.index_305.push(item.properties.index_305);
  //   testMedians.index_306.push(item.properties.index_306);
  //   testMedians.index_307.push(item.properties.index_307);
  //   testMedians.index_308.push(item.properties.index_308);
  //   testMedians.index_309.push(item.properties.index_309);
  //   testMedians.index_310.push(item.properties.index_310);
  //   testMedians.index_311.push(item.properties.index_311);
  //   testMedians.index_312.push(item.properties.index_312);
  //   testMedians.index_401.push(item.properties.index_401);
  //   testMedians.index_402.push(item.properties.index_402);
  //   testMedians.index_403.push(item.properties.index_403);
  //   testMedians.index_404.push(item.properties.index_404);
  //   testMedians.index_405.push(item.properties.index_405);
  //   testMedians.index_406.push(item.properties.index_406);
  //   testMedians.index_407.push(item.properties.index_407);
  //   testMedians.index_408.push(item.properties.index_408);
  //   testMedians.index_409.push(item.properties.index_409);
  //   testMedians.index_410.push(item.properties.index_410);
  //   testMedians.index_411.push(item.properties.index_411);
  //   testMedians.index_412.push(item.properties.index_412);
  //   testMedians.index_501.push(item.properties.index_501);
  //   testMedians.index_502.push(item.properties.index_502);
  //   testMedians.index_503.push(item.properties.index_503);
  //   testMedians.index_504.push(item.properties.index_504);
  //   testMedians.index_505.push(item.properties.index_505);
  //   testMedians.index_506.push(item.properties.index_506);
  //   testMedians.index_507.push(item.properties.index_507);
  //   testMedians.index_508.push(item.properties.index_508);
  //   testMedians.index_509.push(item.properties.index_509);
  //   testMedians.index_510.push(item.properties.index_510);
  //   testMedians.index_511.push(item.properties.index_511);
  //   testMedians.index_512.push(item.properties.index_512);
  //   testMedians.index_513.push(item.properties.index_513);
  //   testMedians.index_514.push(item.properties.index_514);
  //   testMedians.index_515.push(item.properties.index_515);
  //   testMedians.index_516.push(item.properties.index_516);
  // });
  // Object.keys(testMedians).forEach((indexes) => {
  //   testMedians[indexes] = median(testMedians[indexes]);
  // });
  // [1, 2, 3, 4, 5].forEach((item) => {
  //   const regExpSeparator = new RegExp(`index_${item}`, 'g');
  //   const filteredMedians = Object.keys(testMedians).filter((index) =>
  //     index.match(regExpSeparator)
  //   );
  //   testResult[`index_${item}`] =
  //     filteredMedians.reduce((a, b) => a + testMedians[b], 0) /
  //     filteredMedians.length;
  // });

  return formatChartWidgetData(
    {
      sub_index: {
        1: result.index_1,
        2: result.index_2,
        3: result.index_3,
        4: result.index_4,
        5: result.index_5,
      },
    },
    language
  );
};

export const formCardsFromZoom10 = (data, language) => {
  const metrics = {};
  const sub_index = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  };
  data.forEach((feature) => {
    Object.keys(feature.properties).forEach((prop) => {
      if (/index_\d{3}/g.test(prop)) {
        const index = prop.split('index_')[1];
        if (metrics[index]) {
          metrics[index].push(feature.properties[prop]);
        } else {
          metrics[index] = [feature.properties[prop]];
        }
      }
    });
  });

  Object.keys(metrics).forEach((key) => {
    metrics[key] = median(metrics[key]);
  });
  Object.keys(sub_index).forEach((key) => {
    const filteredMetrics = Object.keys(metrics).filter((metric) => {
      const regexp = new RegExp(`index_${key}`, 'g');
      return metric.match(regexp);
    });
    sub_index[key] =
      filteredMetrics.reduce((a, b) => a + metrics[b], 0) /
      filteredMetrics.length;
  });

  return formatCardsWidgetData({ sub_index, metrics }, language);
};

export const pickCardColor = (gradient, value) => {
  let color;
  const bucketsCopy = [...gradient.buckets];
  bucketsCopy.unshift(0);
  bucketsCopy.forEach((bucket, index) => {
    const min_bucket = bucket;
    let max_bucket = bucketsCopy[index + 1];
    if (!max_bucket) {
      max_bucket = Infinity;
    }
    if (value === '100.00') {
      color = gradient.colors[gradient.colors.length - 1];
    } else if (value >= min_bucket && value < max_bucket) {
      color = gradient.colors[index];
    }
  });

  console.log(`TEST:: value: ${value}\ncolor: ${color}`);

  return color;
};

export const pickCardBg = (index, darkMode) => {
  let color = darkMode ? 'rgba(213, 63, 66, 1)' : 'rgba(219,191,45,1)';
  if (index < 30) {
    color = darkMode ? 'rgba(123, 35, 104, 1)' : 'rgba(248,95,95,1)';
  } else if (index > 60) {
    color = darkMode ? 'rgba(247, 157, 27, 1)' : 'rgba(45,219,115,1)';
  }

  if (darkMode) {
    return `linear-gradient(135deg, ${color} 0%, ${color} 48%, rgba(24,26,31,1) 48%, rgba(24,26,31,1) 100%)`;
  }
  return `linear-gradient(135deg, ${color} 0%, ${color} 48%, rgb(242, 242, 242) 48%, rgb(242, 242, 242) 100%)`;
};
