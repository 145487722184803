import { createBrowserRouter } from 'react-router-dom';
import App from '../App.jsx';
import MainPage from '../pages/MainPage/MainPage.jsx';
import Login from '../pages/Login/Login.jsx';
import Register from '../pages/Register/Register.jsx';
import MainPageRestyle from '../pages/MainPage/MainPageRestyle.jsx';
import UserList from '../pages/UserList/UserList.jsx';
import UserView from '../pages/UserView/UserView.jsx';
import DemoMainPage from '../pages/MainPage/DemoMainPage.jsx';
import Chat from '../pages/Chat/Chat.jsx';
import ChatV2 from '../pages/ChatV2/Chat.jsx';

const routes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { path: 'root', element: <MainPageRestyle /> },
      { path: 'user-list', element: <UserList /> },
      { path: 'user/:id', element: <UserView /> },
      { path: 'public', element: <DemoMainPage /> },
      { path: 'chat', element: <Chat /> },
      { path: 'chat-v2', element: <ChatV2 /> },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  // {
  //   path: '/landing',
  //   element: <Landing />,
  // },
]);

export default routes;
