import React, { useEffect, useMemo } from 'react';
import { Button, Card, Form, Input, Select } from 'antd';
import { useUnit } from 'effector-react';
import { CloseOutlined } from '@ant-design/icons';
import { $language } from '../../models/authModel/index.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import {
  age_dictionary,
  gender_dictionary,
  income_dictionary,
} from '../../dictionaries/socdem_dictionaries.js';
import {
  changeActiveFilterEv,
  submitFiltersEv,
} from '../../models/activeFiltersModel/index.js';
import { $userAppointedPreset } from '../../models/dataPresetsModel/index.js';

function filterOption(input, option) {
  return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
}

const ageOptions = Object.keys(age_dictionary).map((item) => {
  return {
    label: age_dictionary[item],
    value: item,
  };
});

const incomeOptions = Object.keys(income_dictionary).map((item) => {
  return {
    label: income_dictionary[item],
    value: item,
  };
});

function SocdemGroupsForm(props) {
  const { form } = props;
  const language = useUnit($language);
  const changeActiveFilter = useUnit(changeActiveFilterEv);
  const submitFilters = useUnit(submitFiltersEv);
  const userAppointedPreset = useUnit($userAppointedPreset);

  const genderOptions = useMemo(() => {
    return Object.keys(gender_dictionary).map((item) => {
      return {
        label: gender_dictionary[item][language],
        value: item,
      };
    });
  }, [language]);

  const onFinish = (values) => {
    changeActiveFilter({ field: 'groups', value: values.groups });
    submitFilters();
  };

  useEffect(() => {
    if (userAppointedPreset) {
      form.setFieldValue(
        'groups',
        userAppointedPreset.preset.activeFilters.socdem_groups
      );
    }
  }, [userAppointedPreset]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        groups: [{}],
      }}
      onFinish={onFinish}
    >
      <Form.List name="groups">
        {(fields, { add, remove }) => (
          <div>
            {fields.map((field, index) => (
              <Card
                key={`socdem-group-${field.key}`}
                title={`${ru_en_page_dictionary.rbp_socdem_group[language]} ${
                  index + 1
                }`}
                size="small"
                extra={
                  <CloseOutlined
                    onClick={() => {
                      remove(field.name);
                    }}
                  />
                }
              >
                <Form.Item
                  label={ru_en_page_dictionary.age[language]}
                  name={[field.name, 'age']}
                >
                  <Select
                    options={ageOptions}
                    allowClear
                    mode="multiple"
                    filterOption={filterOption}
                  />
                </Form.Item>
                <Form.Item
                  label={ru_en_page_dictionary.income[language]}
                  name={[field.name, 'income']}
                >
                  <Select
                    options={incomeOptions}
                    allowClear
                    mode="multiple"
                    filterOption={filterOption}
                  />
                </Form.Item>
                <Form.Item
                  label={ru_en_page_dictionary.gender[language]}
                  name={[field.name, 'gender']}
                >
                  <Select
                    options={genderOptions}
                    allowClear
                    mode="multiple"
                    filterOption={filterOption}
                  />
                </Form.Item>
              </Card>
            ))}
            <Button type="dashed" onClick={() => add()} block>
              + {ru_en_page_dictionary.rbp_socdem_add_group[language]}
            </Button>
          </div>
        )}
      </Form.List>
    </Form>
  );
}

export default SocdemGroupsForm;
