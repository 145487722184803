import { Button, Card, Spin, Tabs, Tooltip } from 'antd';
import { useUnit } from 'effector-react';
import { useEffect, useState } from 'react';
import Icon, {
  DoubleLeftOutlined,
  DownOutlined,
  FallOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  QuestionCircleFilled,
  QuestionCircleOutlined,
  RightOutlined,
  RiseOutlined,
  StarFilled,
  StarOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import style from './TopBar.module.css';
import {
  $indexCards,
  changeModalOpenEv,
} from '../../models/indexCardsModel/index.js';
import {
  $isDarkTheme,
  $isTablet,
  $language,
  $userInfo,
} from '../../models/authModel/index.js';
import {
  wsSendAddFavorites,
  wsSendRemoveFavorites,
} from '../../utils/webSocketConfig.js';
import {
  $activeFilters,
  calculateByMetricEv,
  changeActiveFilterEv,
} from '../../models/activeFiltersModel/index.js';
import { metrics } from '../../data/metrics.js';
import {
  $cardsLoader,
  showLoaderEv,
} from '../../models/webSocketModel/index.js';
import {
  icons_dict,
  small_icons_dict,
  tablet_tab_icons_dict,
} from '../../dictionaries/icons_dict.jsx';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import { $gradientBuckets } from '../../models/gradientModel/index.js';
import { pickCardColor, pickCardBg } from '../../utils/index-widgets-utils.js';
import { $mapLoaded } from '../../models/mapModel/index.js';
import { $showPresets } from '../../models/dataPresetsModel/index.js';
import { $chosenZoom7Hexagon } from '../../models/zoom7Model/index.js';
import { range_values_dict } from '../../dictionaries/public_index_dictionary.js';

function formTabItems(
  indexCards,
  expanded,
  favorites,
  addFavorite,
  removeFavorite,
  changeModalOpen,
  changeActiveFilters,
  calculateByMetric,
  activeFilters,
  language,
  gradient,
  darkMode,
  isTablet
) {
  const fav_indexes = [];
  indexCards.forEach((item) => {
    item.children.forEach((child) => {
      const child_index = +child.index_name.split('index_')[1];
      if (favorites.includes(child_index)) {
        fav_indexes.push(child);
      }
    });
  });

  const tabs = [
    {
      key: '0',
      label:
        favorites.length > 0 ? (
          <Tooltip
            trigger={isTablet ? null : 'hover'}
            title={ru_en_page_dictionary.fav_metrics[language]}
            placement="right"
          >
            <StarFilled className={style.fav_button} />
          </Tooltip>
        ) : (
          <Tooltip
            trigger={isTablet ? null : 'hover'}
            title={ru_en_page_dictionary.no_fav_metrics[language]}
            placement="right"
          >
            <StarFilled className={style.fav_button} />
          </Tooltip>
        ),
      children: (
        <div
          className={style.metric_container}
          style={{
            height: isTablet ? '84vh' : expanded ? '300px' : '145px',
          }}
        >
          <div
            className={style.metric_wrapper}
            style={{
              gridTemplateColumns: !isTablet
                ? `repeat(${Math.round(fav_indexes.length / 2)}, ${
                    expanded ? '155px' : '320px'
                  })`
                : '',
            }}
          >
            {[...Array(Math.ceil(fav_indexes.length / 2)).keys()].map(
              (num, numIndex) => {
                return (
                  <div
                    key={`child-${numIndex}`}
                    className={style.double_metric_wrapper}
                    style={{
                      width:
                        expanded || !fav_indexes[(num + 1) * 2 - 1]
                          ? '155px'
                          : '320px',
                    }}
                  >
                    {[
                      fav_indexes[(num + 1) * 2 - 2],
                      fav_indexes[(num + 1) * 2 - 1],
                    ].map((child, childIndex) => {
                      if (!child) return null;
                      return (
                        <IndexCard
                          className={
                            childIndex === 1 && expanded
                              ? style.metric_card_expanded
                              : ''
                          }
                          key={`child-${childIndex}`}
                          title={child.title}
                          index={child.index}
                          index_name={child.index_name}
                          favorites={favorites}
                          addFavorite={addFavorite}
                          removeFavorite={removeFavorite}
                          changeModalOpen={changeModalOpen}
                          icon={child.icon}
                          dynamics={child.dynamics}
                          dynamicsValue={child.dynamics_value}
                          changeActiveFilters={changeActiveFilters}
                          calculateByMetric={calculateByMetric}
                          activeFilters={activeFilters}
                          gradient={gradient}
                          darkMode={darkMode}
                          isTablet={isTablet}
                        />
                      );
                    })}
                  </div>
                );
              }
            )}
            {/* {fav_indexes.map((child, childIndex) => { */}
            {/*  return ( */}
            {/*    <IndexCard */}
            {/*      key={`child-${childIndex}`} */}
            {/*      title={child.title} */}
            {/*      index={child.index} */}
            {/*      index_name={child.index_name} */}
            {/*      favorites={favorites} */}
            {/*      addFavorite={addFavorite} */}
            {/*      removeFavorite={removeFavorite} */}
            {/*      changeModalOpen={changeModalOpen} */}
            {/*      icon={child.icon} */}
            {/*      dynamics={child.dynamics} */}
            {/*      dynamicsValue={child.dynamics_value} */}
            {/*      changeActiveFilters={changeActiveFilters} */}
            {/*      calculateByMetric={calculateByMetric} */}
            {/*      activeFilters={activeFilters} */}
            {/*      gradient={gradient} */}
            {/*      darkMode={darkMode} */}
            {/*    /> */}
            {/*  ); */}
            {/* })} */}
          </div>
        </div>
      ),
    },
  ];

  return tabs.concat(
    indexCards.map((item, index) => {
      return {
        key: (index + 1).toString(),
        label: (
          <Tooltip
            trigger={isTablet ? null : 'hover'}
            title={item.title}
            placement="right"
          >
            <Icon
              component={
                item.index_name.includes('index')
                  ? isTablet
                    ? tablet_tab_icons_dict[
                        item.index_name.split('index_')[1][0]
                      ]
                    : small_icons_dict[item.index_name.split('index_')[1][0]]
                  : null
              }
              style={{
                marginRight: 'unset',
              }}
            />
          </Tooltip>
        ),
        children: (
          <div
            className={style.metric_container}
            style={{
              height: isTablet ? '84vh' : expanded ? '300px' : '145px',
            }}
          >
            <div
              className={style.metric_wrapper}
              style={{
                gridTemplateColumns: !isTablet
                  ? `repeat(${Math.round(item.children.length / 2)}, ${
                      expanded ? '155px' : '320px'
                    })`
                  : '',
              }}
            >
              {[...Array(Math.ceil(item.children.length / 2)).keys()].map(
                (num, numIndex) => {
                  return (
                    <div
                      key={`child-${numIndex}`}
                      className={style.double_metric_wrapper}
                      style={{
                        width:
                          expanded || !item.children[(num + 1) * 2 - 1]
                            ? '155px'
                            : '320px',
                      }}
                    >
                      {[
                        item.children[(num + 1) * 2 - 2],
                        item.children[(num + 1) * 2 - 1],
                      ].map((child, childIndex) => {
                        if (!child) return null;
                        return (
                          <IndexCard
                            className={
                              childIndex === 1 && expanded
                                ? style.metric_card_expanded
                                : ''
                            }
                            key={`child-${childIndex}`}
                            title={child.title}
                            index={child.index}
                            index_name={child.index_name}
                            favorites={favorites}
                            addFavorite={addFavorite}
                            removeFavorite={removeFavorite}
                            changeModalOpen={changeModalOpen}
                            icon={child.icon}
                            dynamics={child.dynamics}
                            dynamicsValue={child.dynamics_value}
                            changeActiveFilters={changeActiveFilters}
                            calculateByMetric={calculateByMetric}
                            activeFilters={activeFilters}
                            gradient={gradient}
                            darkMode={darkMode}
                            isTablet={isTablet}
                          />
                        );
                      })}
                    </div>
                  );
                }
              )}
              {/* {item.children.map((child, childIndex) => { */}
              {/*  return ( */}
              {/*    <IndexCard */}
              {/*      key={`child-${childIndex}`} */}
              {/*      title={child.title} */}
              {/*      index={child.index} */}
              {/*      index_name={child.index_name} */}
              {/*      favorites={favorites} */}
              {/*      addFavorite={addFavorite} */}
              {/*      removeFavorite={removeFavorite} */}
              {/*      changeModalOpen={changeModalOpen} */}
              {/*      icon={child.icon} */}
              {/*      dynamics={child.dynamics} */}
              {/*      dynamicsValue={child.dynamics_value} */}
              {/*      changeActiveFilters={changeActiveFilters} */}
              {/*      calculateByMetric={calculateByMetric} */}
              {/*      activeFilters={activeFilters} */}
              {/*      gradient={gradient} */}
              {/*      darkMode={darkMode} */}
              {/*    /> */}
              {/*  ); */}
              {/* })} */}
            </div>
          </div>
        ),
      };
    })
  );
}

export default function TopBar() {
  const indexCards = useUnit($indexCards);
  const activeFilters = useUnit($activeFilters);
  const userFavs = useUnit($userInfo)?.favorites;
  const changeActiveFilters = useUnit(changeActiveFilterEv);
  const calculateByMetric = useUnit(calculateByMetricEv);
  const showLoader = useUnit(showLoaderEv);
  const changeModalOpen = useUnit(changeModalOpenEv);
  const cardsLoader = useUnit($cardsLoader);
  const language = useUnit($language);
  const gradient = useUnit($gradientBuckets);
  const darkMode = useUnit($isDarkTheme);
  const isTablet = useUnit($isTablet);
  const mapLoaded = useUnit($mapLoaded);
  const showPresets = useUnit($showPresets);
  const { pathname } = useLocation();

  const [tabsItems, setTabsItems] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [activeTab, setActiveTab] = useState('1');
  const [activeContainer, setActiveContainer] = useState(null);
  const [scrollRight, setScrollRight] = useState(true);
  const [scrollLeft, setScrollLeft] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const onScrollClick = () => {
    if (isTablet) {
      activeContainer.scrollTo({
        top: activeContainer.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      activeContainer.scrollTo({
        top: 0,
        left: activeContainer.scrollWidth,
        behavior: 'smooth',
      });
    }
  };

  const onScrollLeftClick = () => {
    activeContainer.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const showFavs = () => {
    if (activeFilters.excludedIndexes.length === 0) {
      showLoader();
    }
    changeActiveFilters({
      field: 'excludedIndexes',
      value: metrics
        .filter((item) => !userFavs.includes(item))
        .map((item) => `index_${item}`),
    });
  };

  const addFavorite = (index) => {
    const filteredFavorites = [...favorites, +index];
    setFavorites(filteredFavorites);
    wsSendAddFavorites(filteredFavorites);
  };

  const removeFavorite = (index) => {
    const filteredFavorites = favorites.filter((item) => item !== index);
    setFavorites(filteredFavorites);
    if (filteredFavorites.length === 0) {
      setActiveTab('1');
    }
    wsSendRemoveFavorites([index]);
  };

  const onTabClick = (activeKey) => {
    activeContainer?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
    if (activeKey === '0') {
      if (favorites.length > 0) {
        setActiveTab(activeKey);
      }
    } else {
      setActiveTab(activeKey);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const containers = document.getElementsByClassName(
        style.metric_container
      );
      let active_container;
      for (const container of containers) {
        if (container.parentElement.tabIndex === 0) {
          active_container = container;
        }
      }
      setActiveContainer(active_container);
      setScrollRight(true);
      setScrollLeft(false);
      active_container.addEventListener('scroll', (e) => {
        if (isTablet) {
          const { scrollTop } = e.currentTarget;
          const { scrollHeight } = e.currentTarget;
          const { offsetHeight } = e.currentTarget;
          setScrollRight(!(offsetHeight === scrollHeight - scrollTop));
          setScrollLeft(scrollTop > 0);
        } else {
          const containerScrollLeft = e.currentTarget.scrollLeft;
          const { scrollWidth } = e.currentTarget;
          const { offsetWidth } = e.currentTarget;
          setScrollRight(!(offsetWidth === scrollWidth - containerScrollLeft));
          setScrollLeft(containerScrollLeft > 0);
        }
      });
    }, 100);
  }, [activeTab]);

  // FIXME If structure of indexCards stays like this gotta rewrite mutation in state
  useEffect(() => {
    setTabsItems(
      formTabItems(
        indexCards,
        expanded,
        favorites,
        addFavorite,
        removeFavorite,
        changeModalOpen,
        changeActiveFilters,
        calculateByMetric,
        activeFilters,
        language,
        gradient,
        darkMode,
        isTablet
      )
    );
  }, [
    indexCards,
    expanded,
    favorites,
    activeFilters.chosen_metrics,
    language,
    darkMode,
  ]);

  useEffect(() => {
    if (userFavs) {
      setFavorites(userFavs);
    }
  }, [userFavs]);

  return (
    <Spin size="large" spinning={cardsLoader}>
      <Card
        bodyStyle={{
          padding: isTablet ? '10px 0px 0px 10px' : '15px',
          minHeight: expanded ? '330px' : '175px',
          height: '100%',
          transition: '0.3s all',
        }}
        className={[
          !mapLoaded ? style.hide : showPresets ? style.hide : '',
          style.tabs_card,
        ].join(' ')}
        style={{
          minHeight: expanded ? '330px' : '175px',
        }}
      >
        {/* {isTablet ? ( */}
        {/*  <div */}
        {/*    className={style.tablet_metrics_title} */}
        {/*    style={{ color: darkMode ? 'white' : 'black' }} */}
        {/*  > */}
        {/*    Виджеты */}
        {/*  </div> */}
        {/* ) : ( */}
        {/*  '' */}
        {/* )} */}
        {!isTablet && pathname !== '/public' ? (
          expanded ? (
            <Button
              shape="circle"
              className={style.expand_button}
              icon={<DoubleLeftOutlined rotate={90} />}
              onClick={toggleExpand}
            />
          ) : (
            <Button
              shape="circle"
              className={style.expand_button}
              icon={<DoubleLeftOutlined rotate={-90} />}
              onClick={toggleExpand}
            />
          )
        ) : (
          ''
        )}
        {window.innerWidth < 1370 ? (
          !isTablet ? (
            <Button
              className={[
                style.scroll_button,
                style.scroll_horizontal,
                scrollRight ? style.scroll_active : '',
              ].join(' ')}
              onClick={onScrollClick}
              shape="circle"
              icon={<RightOutlined />}
            />
          ) : (
            <Button
              className={[
                style.scroll_button,
                style.scroll_vertical,
                scrollRight ? style.scroll_active : '',
              ].join(' ')}
              onClick={onScrollClick}
              shape="circle"
              icon={<DownOutlined />}
            />
          )
        ) : (
          ''
        )}
        {!isTablet ? (
          <Button
            className={[
              style.scroll_button,
              style.scroll_horizontal_left,
              scrollLeft ? style.scroll_active : '',
            ].join(' ')}
            onClick={onScrollLeftClick}
            shape="circle"
            icon={<LeftOutlined />}
          />
        ) : (
          <Button
            className={[
              style.scroll_button,
              style.scroll_vertical_up,
              scrollLeft ? style.scroll_active : '',
            ].join(' ')}
            onClick={onScrollLeftClick}
            shape="circle"
            icon={<UpOutlined />}
          />
        )}
        <Tabs
          tabPosition={isTablet ? 'top' : 'left'}
          id={pathname === '/public' ? 'top_bar_tabs_public' : 'top_bar_tabs'}
          items={tabsItems}
          activeKey={activeTab}
          tabBarStyle={{
            minWidth: '25px',
            alignItems: 'center',
          }}
          // tabBarExtraContent={{
          //   right: expanded ? (
          //     <DoubleLeftOutlined rotate={90} onClick={toggleExpand} />
          //   ) : (
          //     <DoubleLeftOutlined rotate={-90} onClick={toggleExpand} />
          //   ),
          //   // left: (
          //   //   <StarFilled className={style.fav_button} onClick={showFavs} />
          //   // ),
          // }}
          onChange={onTabClick}
        />
      </Card>
    </Spin>
  );
}
function IndexCard(props) {
  const {
    className,
    title,
    index,
    index_name,
    favorites,
    addFavorite,
    removeFavorite,
    changeModalOpen,
    icon,
    dynamics,
    dynamicsValue,
    changeActiveFilters,
    calculateByMetric,
    activeFilters,
    gradient,
    darkMode,
    isTablet,
  } = props;
  const { pathname } = useLocation();
  const isPublic = pathname === '/public';
  const { chosen_metrics } = activeFilters;

  const chosenZoom7Hexagon = useUnit($chosenZoom7Hexagon);

  const card_index = index_name.includes('index_')
    ? +index_name?.split('_')[1]
    : index_name;

  const onQuestionClick = () => {
    console.log('card_index: -', card_index);
    changeModalOpen(card_index);
  };

  const onMetricClick = () => {
    changeActiveFilters({
      field: 'chosen_metrics',
      value: index_name,
    });
    calculateByMetric();
  };

  let value;
  if (!isPublic) {
    value = index;
  } else if (
    index_name === 'income_avg_range' ||
    index_name === 'tj_lvl_avg' ||
    index_name === 'rent_price_range'
  ) {
    if (chosenZoom7Hexagon) {
      value = range_values_dict[index];
    } else {
      value = '-';
    }
  } else {
    value = index;
  }

  return (
    <div
      style={{ position: 'relative', transition: '0.2s all' }}
      className={className}
    >
      {!isPublic ? (
        <div className={style.fav_icon_wrapper}>
          {favorites.includes(card_index) ? (
            <div
              className={style.fav_card}
              onClick={() => removeFavorite(card_index)}
            >
              <StarFilled style={{ color: darkMode ? 'white' : '#717171' }} />
            </div>
          ) : (
            <div
              className={style.fav_card}
              onClick={() => addFavorite(card_index)}
            >
              <StarOutlined style={{ color: darkMode ? 'white' : '#717171' }} />
            </div>
          )}
        </div>
      ) : (
        ''
      )}
      <div
        className={style.modal_button}
        onClick={onQuestionClick}
        style={{
          color: darkMode ? 'white' : '#717171',
          top: !isPublic ? '30px' : '5px',
        }}
      >
        {!isPublic ? <InfoCircleOutlined /> : <QuestionCircleOutlined />}
      </div>
      <div
        className={[
          isTablet ? style.metric_card_no_hover : style.metric_card,
          // index < 30
          //   ? style.metric_bad
          //   : index > 60
          //     ? style.metric_good
          //     : style.metric_neutral,
          // chosen_metrics.includes(index_name)
          //   ? index < 30
          //     ? style.metric_bad_active
          //     : index > 60
          //       ? style.metric_good_active
          //       : style.metric_neutral_active
          //   : '',
          chosen_metrics.includes(index_name) ? style.card_active : '',
        ].join(' ')}
        style={{
          background: !isPublic
            ? pickCardBg(index, darkMode)
            : pickCardBg(61, darkMode),
          color: darkMode ? 'white' : 'black',
        }}
        // style={{
        //   background: pickCardColor(gradient, index),
        // }}
        // className={[
        //   style.metric_card,
        //   chosen_metrics.includes(index_name) ? style.metric_card_active : '',
        // ].join(' ')}
        onClick={onMetricClick}
      >
        <Icon
          component={icon}
          style={{
            color: darkMode ? 'white' : '#E5E5E5',
            opacity: darkMode ? '0.15' : '1',
          }}
          className={style.metric_card_icon}
        />
        <div
          className={style.metric_card_top_wrapper}
          style={{ textAlign: !isPublic ? 'left' : 'center' }}
        >
          {title}
        </div>
        <div
          className={style.metric_footer}
          style={{
            justifyContent: !isPublic ? 'space-between' : 'center',
          }}
        >
          <div style={{ fontSize: '20px' }}>{value}</div>
          {!isPublic ? (
            <div
              style={{
                fontSize: '12px',
                lineHeight: '14px',
                // color:
                //   dynamics === 0 ? 'black' : dynamics > 0 ? '#2DDB73' : '#F85F5F',
              }}
            >
              {dynamicsValue}
              {dynamics > 0 ? (
                <RiseOutlined />
              ) : dynamics < 0 ? (
                <FallOutlined />
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
        </div>
        {/* <div className={style.metric_card_left}> */}
        {/*  <div className={style.metric_card_top_wrapper}>{title}</div> */}
        {/*  <div style={{fontSize: '20px'}}>{index}</div> */}
        {/* </div> */}
        {/* <div className={style.metric_card_right}> */}
        {/*  <Icon component={icon} style={{color: '#c8e8fa'}}/> */}

        {/* </div> */}
      </div>
    </div>
  );
}
